import { Link, useNavigate } from "react-router-dom";
import Discussion from "./Discussion";
import DiscussionAdmin from "./DiscussionAdmin";

const Chat = () => {
  const navigate = useNavigate();

  return (
    <div>
      <header className="header" id="headerid">
        <Link to="#headerid" className="logo">
          Maverick Brilliance
        </Link>

        <nav class="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <br />
      <div className="grid-container-courses1" style={{ height: "5%" }}>
        <div></div>
        <div>
          <DiscussionAdmin />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Chat;
