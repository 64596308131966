import Header from "./Header.js";
import Footer from "./Footer.js";
import "./heading.css";
import "./paragraph.css";
import "./gridcontainer.css";
import "./button.css";
import "./image.css";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { APIHOST } from "../env.js";

function ResetPassword() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [credentials, setCredentials] = useState({email:'',password:''});

  const handleSubmit = async () => {
    var data = {
      ...credentials,
      password_confirmation: credentials.password,
      token: searchParams.get("token")
    }
    try {
      await axios.post(APIHOST + '/api/reset-password', data)
    } catch (error) {
      alert("Reset failed.")
    }
    
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value })
  }

  console.log(searchParams.get("token"))
  return (
    <div className="page-container">
      <Header />
      <div className="grid-container-forgotpassword">
        <div>
          <img
            className="img-forgotpassword"
            src="https://ngopulse.net/themes/jobcy/images/auth/reset-password.png"
          />
        </div>
        <div>
          <h1 style={{ marginTop: "10%", marginLeft: "25%" }}>
            Reset Password
          </h1>
          <br />
          <p style={{ marginLeft: "25%" }}>Reset Your Password</p>
          <br />
          <div className="container">
          <input
              type="email"
              placeholder="Enter email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              placeholder="Enter New Password"
              name="password"
              value={credentials.email}
              onChange={handleChange}
              required
            />
            <button className="btn-login" onClick={handleSubmit}>
              Reset Password
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ResetPassword;
